import image from "../images/DetailProduct/detail-product.jpg"

export const orders = [
    {
        status: "En proceso",
        orderCode:"#0000826-6531",
        date:"27/04/2021",
        total:"12.49€",
        image: image,
        description: "ROSARIO CON MEDALLA DE LA SAGRADA FAMILIA, EN PLATA",
        quantity:"1",
        price:"7.9",
        subtotal:"12.49",
        shippingStatus:"(por confirmar)(por confirmar)",
    },
    {
        status: "En proceso",
        orderCode:"#0000826-1708",
        date:"25/05/2021",
        total:"16.49€",
        image: image,
        description: "ROSARIO #2 CON MEDALLA DE LA SAGRADA FAMILIA, EN PLATA",
        quantity:"2",
        price:"15.49",
        subtotal:"15.49",
        shippingStatus:"(por confirmar)",
    },
    {
        status: "En proceso",
        orderCode:"#0000826-6531",
        date:"27/04/2021",
        total:"12.49€",
        image: image,
        description: "ROSARIO CON MEDALLA DE LA SAGRADA FAMILIA, EN PLATA",
        quantity:"1",
        price:"7.9",
        subtotal:"12.49",
        shippingStatus:"(por confirmar)(por confirmar)",
    },
    {
        status: "En proceso",
        orderCode:"#0000826-1708",
        date:"25/05/2021",
        total:"16.49€",
        image: image,
        description: "ROSARIO #2 CON MEDALLA DE LA SAGRADA FAMILIA, EN PLATA",
        quantity:"2",
        price:"15.49",
        subtotal:"15.49",
        shippingStatus:"(por confirmar)",
    },
    {
        status: "En proceso",
        orderCode:"#0000826-6531",
        date:"27/04/2021",
        total:"12.49€",
        image: image,
        description: "ROSARIO CON MEDALLA DE LA SAGRADA FAMILIA, EN PLATA",
        quantity:"1",
        price:"7.9",
        subtotal:"12.49",
        shippingStatus:"(por confirmar)(por confirmar)",
    },
    {
        status: "En proceso",
        orderCode:"#0000826-1708",
        date:"25/05/2021",
        total:"16.49€",
        image: image,
        description: "ROSARIO #2 CON MEDALLA DE LA SAGRADA FAMILIA, EN PLATA",
        quantity:"2",
        price:"15.49",
        subtotal:"15.49",
        shippingStatus:"(por confirmar)",
    },
]