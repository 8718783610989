import React from 'react';
import Proptypes from 'prop-types';
import Collapse from "../Collapse";
import OrdersDetail from "./OrderDetail";

export default function OrdersItem(props) {
    const { status, orderCode, date, total, image, description, quantity, price, subtotal, shippingStatus } = props;
    return (
        <div className="OrdersItem">
            <div className="OrdersItem-header">
                <span className="OrdersItem-status">{status}</span>
                <div className="OrdersItem-wrapper">
                    <span className="OrdersItem-label">NÚMERO DE PEDIDO</span>
                    <span className="OrdersItem-code">{orderCode}</span>
                </div>
                <div className="OrdersItem-wrapper">
                    <span className="OrdersItem-label">FECHA</span>
                    <span className="OrdersItem-code">{date}</span>
                </div>
            </div>
            <div className="OrdersItem-body">
                <Collapse total={total}>
                    <OrdersDetail 
                        image={image} 
                        description={description} 
                        quantity={quantity} 
                        price={price} 
                        subtotal={subtotal} 
                        shippingStatus={shippingStatus} 
                        total={total} 
                    />
                </Collapse>
            </div>
        </div>
    )
}

OrdersItem.defaultProps = {
    status: "",
    orderCode: "",
    date: "",
    total: "",
    image: "",
    description: "",
    quantity: "",
    price: "",
    subtotal: "",
    shippingStatus: "",
}

OrdersItem.propTypes = {
    status: Proptypes.string,
    orderCode: Proptypes.string,
    date: Proptypes.string,
    total: Proptypes.string,
    image: Proptypes.string,
    description: Proptypes.string,
    quantity: Proptypes.string,
    price: Proptypes.string,
    subtotal: Proptypes.string,
    shippingStatus: Proptypes.string,
}