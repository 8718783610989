import React from 'react'
import SvgIcon from "../SvgCurve"
import Proptypes from 'prop-types';

export default function OrdersResume(props) {
    const { quantity} = props
    return (
        <div className="OrdersResume">
            <div className="OrdersResume-wrapper">
                <SvgIcon className="OrdersResume-icon" icon="bag" />
                <span className="OrdersResume-text">Mis pedidos</span>
            </div>
            <span className="OrdersResume-quantity">{`mostrando ${quantity} pedidos`}</span>
        </div>
    )
}

OrdersResume.defaultProps = {
    quantity: ''
}

OrdersResume.propTypes = {
    quantity: Proptypes.string
}