import React from 'react'
import Proptypes from 'prop-types';

export default function OrderDetail(props) {
    const { image, description, quantity, price, subtotal, total } = props

    return (
        <div className="OrderDetail">
            <div className="OrderDetail-header">
                <span className="OrderDetail-title">
                    Producto
                </span>
                <div className="OrderDetail-header-wrapper">
                    <span className="OrderDetail-title">Unidades</span>
                    <span className="OrderDetail-title">Precio</span>
                </div>
            </div>
            <div className="OrderDetail-content">
                <div className="OrderDetail-image" style={{backgroundImage: `url(${image})`}} />
                <span className="OrderDetail-description">{description}</span>
                <div className="OrderDetail-info">
                    <span className="OrderDetail-quantity">{quantity}</span>
                    <span className="OrderDetail-price">{`${price}€`}</span>
                </div>
            </div>
            <div className="OrderDetail-bill">
                <div className="OrderDetail-row">
                    <span className="OrderDetail-label">Subtotal</span>
                    <span className="OrderDetail-label">{`${subtotal}€`}</span>
                </div>
                <div className="OrderDetail-row">
                    <span className="OrderDetail-label">Envío</span>
                    <span className="OrderDetail-label">(por confirmar)</span>
                </div>
                <div className="OrderDetail-row">
                    <span className="OrderDetail-label">Total (IVA Incluido)</span>
                    <span className="OrderDetail-label">{total}</span>
                </div>
            </div>
        </div>
    )
}

OrderDetail.defaultProps = {
    image: "",
    description: "",
    quantity: "",
    price: "",
    subtotal: "",
    total: "",
}

OrderDetail.propTypes = {
    image: Proptypes.string,
    description: Proptypes.string,
    quantity: Proptypes.string,
    price: Proptypes.string,
    subtotal: Proptypes.string,
    total: Proptypes.string,
}