import React from 'react'
import Layout from "../../components/Layout"
import AccountLayout from "../../components/Account/AccountLayout"
import OrdersResume from "../../components/Orders/OrdersResume"
import OrdersItem from "../../components/Orders/OrdersItem"
import { orders } from "../../data/orders";

export default function Orders() {

    const renderOrders = () => {
        return orders && orders.map((item) => {
            return (
                <OrdersItem 
                    status={item.status} 
                    orderCode={item.orderCode} 
                    date={item.date} 
                    image={item.image}
                    description={item.description}
                    quantity={item.quantity}
                    price={item.price}
                    subtotal={item.subtotal}
                    shippingStatus={item.shippingStatus}
                    total={item.total}
                />
            )
        })
    }
    return (
        <Layout>
            <AccountLayout>
                <div className="Orders">
                    <OrdersResume quantity="35" />
                    {renderOrders()}
                </div>
            </AccountLayout>
        </Layout>
    )
}
